<template>
  <div id="dashboard">
    <div class="row no-gutters">
      <app-title title="mdl.dispo.title"></app-title>
    </div>
    <div class="row no-gutters">
      <div class="col-10 offset-1">
        <app-breadcrumb items="mdl.bread_home;/mdl;home"></app-breadcrumb>
      </div>
    </div>
    <!--Filtro sm-->
    <div  class="row no-gutters d-block d-lg-none mb-0">

      <div class="m-mdl-dispo__spalla_mobile col-10 offset-1 " v-if="this.loading===false">
        <div class="row no-gutters">
          <div class="col-12 mb-2">
            <b-button variant="outline-primary" class="col-12" size="md" @click="toggleFilter()">
              {{ $t('mdl.dispo.filtro.title') }}
            </b-button>

          </div>
        </div>


        <div class="" v-if="filterOpen">
          <b-form-group id="sedi" :label="this.$t('mdl.dispo.filtro.sede')" label-for="sedi-select"
                        class="m-input">
            <b-form-select id="sedi-select" v-model="filter" :options="optionsFilter" @select="computeFilter()">
            </b-form-select>
          </b-form-group>
        </div>
        <div class=" row " v-if="filterOpen">
          <hr class="col-12">
          <h4 class="col-12 text-center">{{$t('mdl.dispo.oppure')}}</h4>
          <div class="col-12 pt-1">
            <b-button variant="primary" class="col-12 " size="md" @click="newSearch()">{{ $t("mdl.dispo.nuovo") }}
            </b-button>
            <b-button variant="outline-primary" class="col-12 mt-2" size="md" @click="$router.push('/mdl/reserve')">
              {{ $t("mdl.dispo.indietro") }}
            </b-button>


          </div>
        </div>
      </div>
    </div>

    <!-- Prestazioni md+ -->
    <div class="row no-gutters mt-3 d-none d-md-block" v-if="this.loading==false">
      <div class="row col-10 offset-1">
        <h3 class="col-12 pl-0">
          {{ $t('mdl.dispo.prestazionei') }}:
        </h3>
        <div class="col-12">
          <!--        <ul>
                  <li v-for="prest in (showAll?this.prestazioni:this.firstPrestazioni)" v-bind:key="prest"> <h2>{{ prest }}</h2></li>
                  </ul>
                  <ul v-if="this.prestazioni.length!=this.firstPrestazioni.length">
                    <b-link @click="toggleShowAll()">{{$t(showAll?"mdl.unshowall":"mdl.showall")}}</b-link>
                  </ul>-->

          <AppPrestazioni :items="this.info.prestazione"></AppPrestazioni>
        </div>
        <hr class="col-11">
      </div>

    </div>

    <!-- Prestazioni sm-->
    <div class="row no-gutters mt-0 d-block d-md-none" v-if="this.loading==false">
      <div class="col-10 offset-1">
        <h4 class="col-2 pl-0">
          <strong>{{ $t('mdl.dispo.prestazionei') }}:</strong>
        </h4>
        <!--<ul>
        <li v-for="prest in (showAll?this.prestazioni:this.firstPrestazioni)" v-bind:key="prest"> <strong>{{ prest }}</strong></li>
        </ul>
        <ul v-if="this.prestazioni.length!=this.firstPrestazioni.length">
          <b-link @click="toggleShowAll()">{{$t(showAll?"mdl.unshowall":"mdl.showall")}}</b-link>
        </ul>
      </div>-->
        <AppPrestazioni :items="this.info.prestazione" mode="p"></AppPrestazioni>
      </div>

      <hr class="col-11">
    </div>


    <!--Dispo -->
    <div class="row no-gutters ">
      <div class="col-10 offset-1">
        <!--Filtro md+-->
        <div class="m-mdl-dispo__spalla d-none d-lg-block   col-lg-4 col-md-6" v-if="this.loading===false">
          <div class="row no-gutters">
            <h4>{{ $t('mdl.dispo.filtro.title') }}</h4>
          </div>


          <div class="">
            <b-form-group id="sedi" :label="this.$t('mdl.dispo.filtro.sede')" label-for="sedi-select"
                          class="m-input">
              <b-form-select id="sedi-select" v-model="filter" :options="optionsFilter" @select="computeFilter()">
              </b-form-select>
            </b-form-group>
          </div>
          <div class="col-12  pt-2 mr-0 pr-0 row ">

            <hr class="col-12">
            <h4 class="col-12 text-center">{{$t('mdl.dispo.oppure')}}</h4>
            <b-button variant="outline-primary" class="mr-3 mt-2" size="md" @click="$router.push('/mdl/reserve')">
              {{ $t("mdl.dispo.indietro") }}
            </b-button>


            <b-button variant="primary" class="mt-2" size="md" @click="newSearch()">{{ $t("mdl.dispo.nuovo") }}
            </b-button>

          </div>
        </div>


        <!--Dispo -->
        <div class="col-md-12 col-lg-8 ml-0 pl-0 pr-0" style="overflow-y: scroll;height:700px">

          <!--NO DISPO-->
          <div class="row col-12 " v-if="this.visibleSlots.length===0" style="text-align: center">
            <h3 class="col-12  mt-1 mb-3 " >{{ $t('mdl.dispo.nodispo') }}</h3>
            <div class="col-12 mb-5" >
              <b-button variant="primary" size="md" @click="$router.push('/mdl/reserve')">{{ $t("mdl.dispo.back") }}
              </b-button>
            </div>
          </div>

          <div class="ml-0 " v-for="month in this.visibleSlots" v-bind:key="month.key">

            <div>
              <h2>{{ $t('mdl.dispo.month.M' + month.mese) }}</h2>
            </div>
            <div class="m-mdl-dispo__container p-0 mr-1 ">
              <div class="m-mdl-dispo__box  pl-2 pr-2 pr-md-4 row align-items-center"  v-for="day in month.days" v-bind:key="day.key">
                <div class="m-mdl-dispo__day col-3 d-none d-md-block text-center">
                  <label class="m-mdl-dispo-weekday">{{ $t('mdl.dispo.weekday.W' + day.weekday) }}</label>
                  <label class="m-mdl-dispo-monthday">{{ (day.giorno) }}</label>
                  <label class="m-mdl-dispo-weekday">{{ $t('mdl.dispo.month.M' + month.mese) }} <br> {{month.anno}}</label>
                </div>
                <div class="m-mdl-dispo__day-sm col-3 d-block d-md-none ">
                  <label
                      class="m-mdl-dispo-weekday ml-2">{{ $t('mdl.dispo.weekday.W' + day.weekday).substr(0, 1) }}</label>
                  <label class="m-mdl-dispo-monthday ml-2">{{ (day.giorno) }}</label>
                  <label class="m-mdl-dispo-weekday-sm">{{ $t('mdl.dispo.month.M' + month.mese) }}</label>

                  <label class="m-mdl-dispo-weekday-sm">{{ month.anno }}</label>

                </div>



                <div class="m-mdl-dispo__info col-7 col-md-6">
                <label class="m-mdl-dispo-infoline col-12 ">{{ $t('mdl.dispo.disponibilita') }}:<strong>
                  {{ day.count }}</strong></label>
                <label class="m-mdl-dispo-infoline col-12">{{ $t('mdl.dispo.presso') }}:<strong>
                  {{ day.sedi.length }} {{ $t('mdl.dispo.sedi') }}</strong></label>
                <label class="m-mdl-dispo-infoline col-12">{{ $t('mdl.dispo.dalleore') }}:<strong>
                  {{ day.first }}</strong></label>
                <label class="m-mdl-dispo-infoline col-12">{{ $t('mdl.dispo.durata') }}:<strong>
                  {{ day.durata }} {{ $t('mdl.dispo.minuti') }}</strong></label>
                  </div>


                <div class="col-1 col-md-3 col-lg-3 text-right d-flex">
                  <b-button class="d-none d-md-flex" variant="primary" size="md" @click="vedi(day)">{{ $t("mdl.dispo.vedi") }}
                  </b-button>

                  <b-button class="d-block d-md-none" variant="outline-primary" size="sm" @click="vedi(day)">></b-button>


                </div>

              </div>
            </div>
          </div>

          <!--Estendi + -->
          <div class="row col-12" v-if="this.visibleSlots.length!==0">
            <div class="col-2 offset-5" style="text-align: center">
              <b-button variant="outline-primary" class="m-mdl-dispo__plus" @click="startNext()">+</b-button>
            </div>
          </div>
          <!--Estendi Bottone-->
          <div class="row col-12" v-if="this.visibleSlots.length===0" style="text-align: center">
            <h3 class="col-12">{{$t('mdl.dispo.oppure')}}</h3>
            <div class="col-12 mb-5">
              <b-button  variant="primary" size="md" @click="startNext()">{{ $t("mdl.dispo.extend") }}
              </b-button>
            </div>

          </div>

        </div>
      </div>
    </div>

    <b-modal ref="dispo-dialog"
             id="dispoDlg"
             centered
             modal-class="o-modal"
             content-class="o-modal__content m-mdl-dispo__no-flex"
             header-class="o-modal__header"
             dialog-class="o-modal__dialog"
             body-class="o-modal__body o-modal__body--response "
             ok-title="ok-title"
             :cancel-disabled="true"
             :hide-footer="true"
             :hide-header-close="true"
             :hide-header="true"
             size="lg"
    >
      <DlgMdlFiltro :plan="this.plan" @onhide="hideSearch()" @ondata="showNewSearch()"></DlgMdlFiltro>
    </b-modal>
    <b-modal ref="slot-dialog"
             id="slot"
             centered
             modal-class="o-modal"
             content-class="o-modal__content m-mdl-dispo__no-flex"
             header-class="o-modal__header"
             dialog-class="o-modal__dialog"
             body-class="o-modal__body o-modal__body--response d-block p-2 p-lg-5"
             ok-title="ok-title"
             :cancel-disabled="true"
             :hide-footer="true"
             :hide-header-close="true"
             :hide-header="true"
             size="lg"
    >

      <h2 v-if="selectDay!=null" class=" mt-0">
        <strong>Orari </strong>
      </h2>
      <hr v-if="selectDay!=null">


      <div class="row no-gutters mt-3 d-block" v-if="this.loading==false && this.selectDay!=null">
        <div class="col-12 " style="text-align: left !important;">
          <div>{{ $t('mdl.dispo.prestazionei') }}</div>
          <!-- <ul>
           <li v-for="prest in  (showAll?this.prestazioni:this.firstPrestazioni)" v-bind:key="prest"> <strong>{{ prest }}</strong></li>
           </ul>
           <ul v-if="this.prestazioni.length!=this.firstPrestazioni.length">
             <b-link @click="toggleShowAll()">{{$t(showAll?"mdl.unshowall":"mdl.showall")}}</b-link>
           </ul>-->
          <AppPrestazioni :items="this.info.prestazione" mode="ul-sm"></AppPrestazioni>
        </div>
      </div>
      <div class="row no-gutters mt-1 m-mdl-dispo__white-panel col-12"
           v-if="this.loading==false && this.selectDay!=null">

        <h3 class="text-uppercase col-12 mt-2 mb-2">
          {{ $t('mdl.dispo.weekday.W' + selectDay.weekday) + ' ' + parseInt(selectDay.giorno) + ' ' + $t('mdl.dispo.month.M' + selectDay.mese) + " " + selectDay.anno }}</h3>

        <div v-if="selectDay!=null" class="col-12 d-none d-md-block m-mdl-dispo__white-scroll" style="height: 500px;width:100%">
          <div v-for="slot in selectDay.slots" v-bind:key="slot.slot_guid"
               class="m-mdl-dispo__white-row mt-1 pt-2 pb-2">
            <div class="m-mdl-dispo__button mt-2 d-none d-md-block">
              <b-button variant="primary" size="md" @click="prenota(slot)">{{ $t("mdl.dispo.prenota") }}
              </b-button>
            </div>

            <div class="m-mdl-dispo__dispo col-sm-12 col-md-8 pl-0 " style="text-align: left">


              <label class="m-mdl-dispo-infoline col-sm-12 col-md-10">{{ $t('mdl.dispo.ore') }}:<strong>
                {{ slot.ora_inizio }}</strong></label>
              <label class="m-mdl-dispo-infoline col-sm-12 col-md-10">{{ $t('mdl.dispo.medico') }}:<strong>
                {{ slot.operatore_sanitario==null?$t("mdl.dispo.no_medico"):slot.operatore_sanitario}}</strong></label>
              <label class="m-mdl-dispo-infoline col-sm-12 col-md-10">{{ $t('mdl.dispo.presso') }}:<strong>
                {{ slot.indirizzo_visite }}</strong></label>

            </div>
            <div class="d-sm-block d-md-none mt-3">
              <b-button variant="primary" class="col-12" size="md" @click="prenota(slot)">{{ $t("mdl.dispo.prenota") }}
              </b-button>
            </div>
          </div>
        </div>

        <div v-if="selectDay!=null" class="col-12 d-block d-md-none m-mdl-dispo__white-scroll" style="width:100%">
          <div v-for="slot in selectDay.slots" v-bind:key="slot.slot_guid"
               class="m-mdl-dispo__white-row mt-1 pt-2 pb-2">
            <div class="m-mdl-dispo__button mt-2 d-none d-md-block">
              <b-button variant="primary" size="md" @click="prenota(slot)">{{ $t("mdl.dispo.prenota") }}
              </b-button>
            </div>

            <div class="m-mdl-dispo__dispo col-sm-12 col-md-8 pl-0 " style="text-align: left">


              <label class="m-mdl-dispo-infoline col-sm-12 col-md-10">{{ $t('mdl.dispo.ore') }}:<strong>
                {{ slot.ora_inizio }}</strong></label>
              <label class="m-mdl-dispo-infoline col-sm-12 col-md-10">{{ $t('mdl.dispo.medico') }}:<strong>
                {{ slot.operatore_sanitario }}</strong></label>
              <label class="m-mdl-dispo-infoline col-sm-12 col-md-10">{{ $t('mdl.dispo.presso') }}:<strong>
                {{ slot.indirizzo_visite }}</strong></label>

            </div>
            <div class="d-sm-block d-md-none mt-3">
              <b-button variant="primary" class="col-12" size="md" @click="prenota(slot)">{{ $t("mdl.dispo.prenota") }}
              </b-button>
            </div>
          </div>
        </div>

      </div>
      <div class="col-10 offset-1 mt-5">
        <b-button variant="outline-primary" size="md" class="text-uppercase mt-3" @click="cancelOra()"
        >{{ $t('mdl.home.filtro.annulla') }}
        </b-button>

      </div>
    </b-modal>

  </div>
</template>

<script>

import AppBreadcrumb from "@/components/molecules/AppBreadcrumb";
import AppTitle from "@/components/molecules/AppTitle";
import router from "@/router";
import apiAxios from "@/libs/apiAxios";
import DlgMdlFiltro from "@/components/molecules/DlgMdlFiltro";
import AppPrestazioni from "@/components/molecules/AppPrestazioni";

export default {
  name: "disponibilita",
  components: {AppPrestazioni, DlgMdlFiltro, AppBreadcrumb, AppTitle},


  props: {},
  data() {
    return {
      info: null,
      slots: [],
      visibleSlots: [],
      loading: true,
      errorStr: null,
      filter: '*',
      filterOpen: false,
      interno: "I",
      selectDay: null,
      selectSlot: null,
      showAll: false,
      plan: null,
      next: null,
      optionsInternoEsterno: [
        {value: "I", text: "Interno"},
        {value: "E", text: "Esterno"},
      ],
      optionsFilter: [
        {value: "*", text: "mdl.dispo.filter.allsedi"}
      ]
    };
  },
  computed: {
    prestazioni() {
      if (this.info == null)
        return [];
      if (this.info.prestazione == null)
        return [];
      return this.info.prestazione.split('\n');
    },
    firstPrestazioni() {


      let res = [];
      if (this.prestazioni.length > 0)
        res.push(this.prestazioni[0]);
      if (this.prestazioni.length > 1)
        res.push(this.prestazioni[1]);
      return res;
    }
  },
  watch: {
    filter: {
      handler() {
        this.computeFilter();
      },
      deep: true,
    }
  },
  created() {
    this.loadData();
  },
  methods: {

    startNext() {
      apiAxios.post("/mdl/disponibilita", this.next).then(result => {
        const data = result.data.MdlDispoResult;
        if (data.success) {
          let request = JSON.parse(atob(sessionStorage.getItem('mdlrequest')));
          if (request != null) {
            let mAct = -1;
            let mActKey = null;
            if (request.data.slots.length > 0) {
              mAct = request.data.slots.length - 1;
              mActKey = request.data.slots[mAct].key;
            }
            if (mActKey != null) {
              if (data.data.slots[0].key != mActKey) {
                mAct = -1;
              }
            }

            for (var m = 0; m < data.data.slots.length; m++) {
              if (mAct === -1) {
                request.data.slots.push(data.data.slots[m]);
              } else {
                for (var d = 0; d < data.data.slots[m].days.length; d++) {
                  request.data.slots[mAct].days.push(data.data.slots[m].days[d])
                }
              }
            }

            request.data.intervallo.fine = data.data.intervallo.fine;
            request.data.intervallo.next = data.data.intervallo.next;

            sessionStorage.setItem('mdlrequest', btoa(JSON.stringify(request)));

            this.loadData()
            this.computeFilter();
          }
        }
      })
    },
    newSearch() {
      this.$refs["dispo-dialog"].show();
    },
    showNewSearch() {
      this.loadData();
      this.$refs["dispo-dialog"].hide();
    },
    hideSearch() {
      this.$refs["dispo-dialog"].hide();
    },
    prenota(slot) {
      router.push("/mdl/concludi-pren?slot=" + slot.guid_slot);
    },
    cancelOra() {
      this.$refs["slot-dialog"].hide();
    },
    vedi(day) {
      this.selectDay = day;
      this.$refs["slot-dialog"].show();
    },
    getDaySlots(mKey, dKey) {
      const slots = this.slots;
      const month = slots[mKey];
      return month[dKey];
    },
    minHour(h1, h2) {
      if (h1 < h2)
        return h1;
      return h2;
    },
    computeFilter() {
      this.visibleSlots = [];

      for (var m = 0; m < this.slots.slots.length; m++) {
        const month = this.slots.slots[m];
        let dMonth = {
          mese: month.key.split("_")[0],
          anno: month.key.split("_")[1],
          days: []
        };
        for (var d = 0; d < month.days.length; d++) {
          const day = month.days[d];
          let dDay = {
            mese: dMonth.mese,
            anno: dMonth.anno,
            giorno: day.key.split("_")[0],
            weekday: 0,
            count: 0,
            durata: 0,
            first: '23:59',
            sedi: [],
            slots: []
          }
          for (var s = 0; s < day.slots.length; s++) {
            const slot = day.slots[s];
            if (slot.sede === this.filter || this.filter === '*') {
              dDay.count++;
              if (dDay.sedi.indexOf(slot.sede) === -1) {
                dDay.sedi.push(slot.sede)
              }
              dDay.first = this.minHour(dDay.first, slot.ora_inizio);
              dDay.durata = slot.durata_media_minuti;
              dDay.weekday = slot.weekday;

              dDay.slots.push(slot);
            }
          }
          if (dDay.count > 0) {
            dMonth.days.push(dDay)
          }
        }
        if (dMonth.days.length > 0) {
          this.visibleSlots.push(dMonth);
        }

      }


    },
    toggleFilter() {
      this.filterOpen = !this.filterOpen;
    },
    toggleShowAll() {
      this.showAll = !this.showAll;
    },
    loadData() {
      if (sessionStorage.getItem('mdlrequest') == null) {
        router.push("/mdl/reserve");
        return;
      }
      this.loading = true;

      let data = null;
      try {
        let tmp = sessionStorage.getItem('mdlrequest');
        tmp = atob(tmp);
        data = JSON.parse(tmp);
        if (data != null) {
          this.info = data.query;
          this.slots = data.data;
          this.next = {
            data_inizio: data.data.intervallo.next,
            prestazione: data.query.prestazione,
            tipo: data.query.tipo,
            episodio: data.query.episodio,
            sedi: data.data.sedi
          };

          this.plan = {
            episodio: {
              guid: data.query.episodio
            },
            prestazioni: data.query.prestazione == null ? [] : data.query.prestazione.split('\n')
          };

          let sedi = [{value: "*", text: this.$t('mdl.dispo.filtro.sede_all')}];
          for (var s = 0; s < data.data.sedi.length; s++) {
            let i = data.data.sedi[s].indirizzo;
            if (i.length > 25) {
              i = i.substring(0, 25) + '...';
            }
            const t = {
              value: data.data.sedi[s].id,
              text: i
            };
            sedi.push(t);
          }
          this.optionsFilter = sedi;


          this.computeFilter();
        } else {
          this.errorStr = "ERR_DATA";
        }
      } catch (ex) {
        this.errorStr = "ERR_DATA"
      }
      this.loading = false;
    }
  }
};
</script>
